import { useEffect, useState } from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import mexicoFlag from '../../../assets/icons/mx.png';
import usaFlag from '../../../assets/icons/us.png';
import styles from "./LanguageToogle.module.scss";
import i18n from '../../../i18n';
import { useTranslation } from 'react-i18next';

function LanguageToogle() {
    const {t} = useTranslation();
    const {changeLanguage, languages} = i18n;
    const defaultLanguage = languages?.[0] ?? 'es';
    const [languageValue, setLanguageValue] = useState(defaultLanguage);    
    const languagesOptions = [
        { name: t("Languages.Spanish"), value: 'es', flag: mexicoFlag},
        { name: t("Languages.English"), value: 'en', flag: usaFlag },
      ];
    useEffect(() => {
        changeLanguage(languageValue)
    }, [languageValue]);
            
  return (
    <ButtonGroup className={styles.language}>
      <DropdownButton as={ButtonGroup} title={t("Actions.ChangeLanguage")} defaultValue={languageValue} variant='outline-secondary' size='sm' id="bg-nested-dropdown" onSelect={(eventKey) => setLanguageValue(eventKey ?? languageValue)} >
        {languagesOptions.map((languageOption) => (
          <Dropdown.Item           
            key={languageOption.value}
            id={languageOption.value}
            eventKey={languageOption.value}                        
          >
           {languageOption.name}
          </Dropdown.Item>
        ))}        
      </DropdownButton>
        {languagesOptions.map((languageOption, idx) => (
          <ToggleButton            
            key={idx}
            id={`radio-${idx}`}
            type="radio"
            variant='outline-secondary'
            name="radio"
            value={languageOption.value}
            checked={languageValue === languageOption.value}
            onChange={(e) => setLanguageValue(e.currentTarget.value)}   
          >
            <img src={languageOption.flag} />
          </ToggleButton>
        ))}
    </ButtonGroup>
  );
}

export default LanguageToogle;
