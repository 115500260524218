import { useTranslation } from 'react-i18next';
import Carousel from 'react-bootstrap/Carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeftAlt, faQuoteRightAlt } from "@fortawesome/free-solid-svg-icons";
import Section from '../common/Section';
import styles from "./ReviewsSection.module.scss";
import transparent from '../../../assets/images/transparent-image.png';
import { Card } from 'react-bootstrap';


function ReviewsSection() {
    const { t } = useTranslation();
  return (
    <Section>
        <Card className={styles.reviewCard}>
            <Carousel data-bs-theme="dark">
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={transparent}
                    alt={t("Reviews.Title")}
                    />
                    <Carousel.Caption>
                        <FontAwesomeIcon icon={faQuoteLeftAlt} />                    
                        <p>{t("Reviews.1.Content")}</p>
                        <FontAwesomeIcon icon={faQuoteRightAlt} />                    
                        <h5>{t("Reviews.1.Author")}</h5>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={transparent}
                    alt={t("Reviews.Title")}
                    />
                    <Carousel.Caption>
                        <FontAwesomeIcon icon={faQuoteLeftAlt} />                    
                        <p>{t("Reviews.2.Content")}</p>
                        <FontAwesomeIcon icon={faQuoteRightAlt} />                    
                        <h5>{t("Reviews.2.Author")}</h5>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </Card>
    </Section>
  );
}

export default ReviewsSection;