import { useTranslation } from 'react-i18next';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Section from '../common/Section';
import styles from "./WhyChooseUsSection.module.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserDoctor, faHouseMedicalCircleCheck, faHandHoldingDollar, faCalendarCheck } from "@fortawesome/free-solid-svg-icons";

function WhyChooseUsSection() {
    const { t } = useTranslation();
  return (
    <Section title={t("WhyChooseUs.Title")}>
        <Row xs={1} sm={2} lg={4} className="g-4">
            <Col key={1}>
                <Card className={styles.reason}>
                    <FontAwesomeIcon icon={faUserDoctor} />                                    
                    <Card.Body>
                    <h5>{t("WhyChooseUs.Reasons.Specialists.Title")}</h5>
                    <Card.Text>
                        {t("WhyChooseUs.Reasons.Specialists.Content")}
                    </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
            <Col key={2}>
                <Card className={styles.reason}>
                    <FontAwesomeIcon icon={faHouseMedicalCircleCheck} />                                    
                    <Card.Body>
                    <h5>{t("WhyChooseUs.Reasons.Recovery.Title")}</h5>
                    <Card.Text>
                        {t("WhyChooseUs.Reasons.Recovery.Content")}
                    </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
            <Col key={3}>
                <Card className={styles.reason}>
                    <FontAwesomeIcon icon={faHandHoldingDollar} />                                    
                    <Card.Body>
                    <h5>{t("WhyChooseUs.Reasons.Cost.Title")}</h5>
                    <Card.Text>
                        {t("WhyChooseUs.Reasons.Cost.Content")}
                    </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
            <Col key={4}>
                <Card className={styles.reason}>
                    <FontAwesomeIcon icon={faCalendarCheck} />                                    
                    <Card.Body>
                    <h5>{t("WhyChooseUs.Reasons.Planning.Title")}</h5>
                    <Card.Text>
                        {t("WhyChooseUs.Reasons.Planning.Content")}
                    </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </Section>
  );
}

export default WhyChooseUsSection;