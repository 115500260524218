import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Row from 'react-bootstrap/Row';
import * as formik from 'formik';
import * as yup from 'yup';
import PrivacyNotice from './PrivacyNotice';
import { EventRegistrationModel } from '../../../models/RegistrationModel';
import { eventRegister } from '../../../services/emailService';
import emailjs from "@emailjs/browser";
import { useEffect, useState } from 'react';
import { EmailJS } from '../../../constants/services';
import { FormikProps } from 'formik';
import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

function EventRegistration() {
  const { Formik } = formik;
  const { t, i18n } = useTranslation();  
  const [loading, setLoading] = useState(false);    
  const [formikRef] = useState<React.RefObject<FormikProps<EventRegistrationModel>>>(React.createRef<FormikProps<EventRegistrationModel>>());
  const phoneRegEx = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
  const schema = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().email().required(),
    telephone: yup.string().matches(phoneRegEx).required(),
    terms: yup.bool().required().oneOf([true], t("ContactUs.Validations.Terms")),
  });
  const initialValues : EventRegistrationModel = {
    identifier:window.location.href,
    firstName: '',
    lastName: '',
    email: '',
    telephone: '',
    terms: false,
    language: i18n.language,
  }
  async function onSubmit(): Promise<void> {  
    try {
      setLoading(true);
      await eventRegister(formikRef.current?.values);
    } catch (err) {
      console.error(err);
    }
    finally {
      setLoading(false);
    }
  }

  useEffect(() => emailjs.init(EmailJS.PublicKey), []);
  
  return (
    <Formik
      innerRef={formikRef as never}
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {({ handleSubmit, handleChange, values, touched, errors }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Row >
            <Form.Group
              as={Col}
              md="12"
              controlId="validationFirstName"
              className="position-relative"
            >
              <FloatingLabel
                controlId="floatingFirstName"              
                label={t("ContactUs.Fields.FirstName")}
                className="mb-2"
              >
                <Form.Control
                  type="text"
                  name="firstName"
                  placeholder={t("ContactUs.Fields.FirstName")}             
                  value={values.firstName}
                  onChange={handleChange}
                  isInvalid={!!errors.firstName}
                  isValid={touched.firstName && !errors.firstName}
                  maxLength={50}                  
                />
              </FloatingLabel>              
            </Form.Group>
            <Form.Group
              as={Col}
              md="12"
              controlId="validationLastName"
              className="position-relative"
            >
              <FloatingLabel
                controlId="floatingLastName"              
                label={t("ContactUs.Fields.LastName")}
                className="mb-2"
              >
                <Form.Control
                  type="text"
                  name="lastName"
                  placeholder={t("ContactUs.Fields.LastName")}             
                  value={values.lastName}
                  onChange={handleChange}
                  isInvalid={!!errors.lastName}
                  isValid={touched.lastName && !errors.lastName}
                  maxLength={100}
                />
              </FloatingLabel>              
            </Form.Group>
          </Row>
          <Row className="mb-2">
            <Form.Group
              as={Col}
              md="12"
              controlId="validationEmail"
              className="position-relative"
            >
              <FloatingLabel
                controlId="floatingEmail"              
                label={t("ContactUs.Fields.Email")}
                className="mb-2"
              >
                <Form.Control
                  type="email"
                  name="email"
                  placeholder={t("ContactUs.Fields.Email")}             
                  value={values.email}
                  onChange={handleChange}
                  isInvalid={!!errors.email}
                  isValid={touched.email && !errors.email}
                  maxLength={100}
                />
              </FloatingLabel>              
            </Form.Group>
            <Form.Group
              as={Col}
              md="12"
              controlId="validationTelephone"
              className="position-relative"
            >
              <FloatingLabel
                controlId="floatingTelephone"              
                label={t("ContactUs.Fields.Telephone")}
                className="mb-2"
              >
                <Form.Control
                  type="text"
                  name="telephone"
                  placeholder={t("ContactUs.Fields.Telephone")}             
                  value={values.telephone}
                  onChange={handleChange}
                  isInvalid={!!errors.telephone}
                  isValid={touched.telephone && !errors.telephone}
                  maxLength={20}
                />
              </FloatingLabel>              
            </Form.Group>
          </Row>
          <Form.Group className="position-relative">
            <Form.Check
              required
              name="terms"
              className='d-inline'
              onChange={handleChange}
              isInvalid={!!errors.terms}
              isValid={touched.terms && !errors.terms}
              id="validationTerms"
            >              
            </Form.Check>
            <span>
              {t("Event.Registration.Fields.Terms")}
            </span>
            <PrivacyNotice linkText={t("CopyRight.PrivacyNotice.Title")}/>
          </Form.Group >
          <div>
            <Button variant='success' disabled={loading} type="submit">{t("Actions.Register")}
            {loading && <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"              
            />}
            </Button>            
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default EventRegistration;