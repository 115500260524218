import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';
import Section from '../common/Section';
import Card from 'react-bootstrap/Card';
import styles from "./EventInfoSection.module.scss";
import medicalTourism from '../../../assets/images/medical-tourism.jpg';
import eventInvitationEn from '../../../assets/images/event-invitation-en.jpg';
import eventInvitationEs from '../../../assets/images/event-invitation-es.jpg';
import { Button, Col, Modal, ModalProps, Row } from 'react-bootstrap';
import EventRegistration from '../common/EventRegistration';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays, faPhone, faMapLocationDot, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

function EventInfoModal(props:ModalProps) {
    const { t } = useTranslation();  
    const { language } = i18n;
    const eventInvitation = language.substring(0,2) === 'es' ? eventInvitationEs : eventInvitationEn;
      return (
        <Modal          
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          animation
          contentClassName={styles.modalContainer}          
        >
          <Modal.Header id='eventModalHeader' closeButton />
          <Modal.Body>
            <Card id='eventModal'>
              <Card.Img src={eventInvitation}  />
              <Card.ImgOverlay />
            </Card>
          </Modal.Body>
        </Modal>
      );
    }

function EventInvitation() {
  const { t } = useTranslation();  
    return (
      <Card id='invitation' className={styles.invitation}>
      <Card.Body>
            <Row className='text-center'>
              <h6>"{t("Company")}" {t("Event.Invitation.Description")}</h6>
            </Row>
            <Row className='text-center'>
              <h3>"{t("Event.Invitation.Name")}"</h3>
            </Row>
            <Row className='text-left'>
              <h5>{t("Event.Invitation.Information")}</h5>
            </Row>
            <Row>
              <Col>
                <FontAwesomeIcon icon={faCalendarDays} />
                {t("Event.Invitation.Schedule.Date")}
                {t("Event.Invitation.Schedule.Time")}
              </Col>
            </Row>      
            <Row>
              <Col>
                <FontAwesomeIcon icon={faMapLocationDot} />                    
                <a href="https://maps.app.goo.gl/SgxsHopdSqCMggCS6" target='_blank'>
                  {t("Event.Invitation.Address")}
                </a>
              </Col>
            </Row>      
            <Row className='text-left'>
              <h5>{t("Event.Invitation.Speakers.Title")}</h5>
            </Row>
            <Card className={styles.presenters}>
              <Row>
              <span>{t("Event.Invitation.Speakers.1")}</span>
              </Row>
              <Row>
              <span>{t("Event.Invitation.Speakers.2")}</span>
              </Row>
              <Row>
              <span>{t("Event.Invitation.Speakers.3")}</span>
              </Row>
              <Row>
              <span>{t("Event.Invitation.Speakers.4")}</span>
              </Row>
              <Row>
              <span>{t("Event.Invitation.Speakers.5")}</span>
              </Row>
            </Card>
            <Row className='text-left'>
              <h5>{t("Event.Invitation.Presenter.Description")}</h5>
            </Row>
            <Card className={styles.presenters}>
            <Row>
              <span>{t("Event.Invitation.Presenter.Name")}</span>
            </Row>
            </Card>
            <Row className='text-center'>
              <Col>
              <h5>{t("Event.Invitation.Cost")}</h5>
              </Col>
            </Row>
            {/*<div className='text-right'>
              <Button variant='success' type="submit" href={`https://api.whatsapp.com/send/?phone=+524152866003&text=${t("Event.Invitation.Register.Text")}`} target='_blank' >
                {t("Event.Invitation.Register.Title")}                         
                <FontAwesomeIcon icon={faWhatsapp} />  
              </Button>
            </div>      */}                       
      </Card.Body>
    </Card>
    );
  }    
 function EventInfoSection(): ReactElement {
    const { t } = useTranslation();
    const [show, setShow] = useState(true);    
    return (
      <>
        <Section id="event" className={styles.event}>
            <Card className={styles.message}>
                <Card.Body>
                <Card.Title className={styles.title}>{t("Welcome.Title", {brand: t("Brand")})}</Card.Title>
                <Card.Text className={styles.messageContent}>
                    {t("Welcome.Content")}
                </Card.Text>
                </Card.Body>
            </Card>
            <Card className={styles.imageContainer}>                
                <Card.Img src={medicalTourism}  />
                <Card.ImgOverlay >
                  <Row xs={1} lg={2} className="g-4">
                    <Col key={1}>
                      <EventInvitation />
                    </Col>
                    <Col key={2}>
                      <Card id='registration' className={styles.registration}>
                          <Card.Title>{t("Event.Registration.Title")}</Card.Title>
                          <Card.Body>
                            <EventRegistration></EventRegistration>
                          </Card.Body>
                      </Card>            
                    </Col>
                  </Row>              
                </Card.ImgOverlay>
            </Card>
        </Section>
        <Section id='eventMobile' className={styles.eventMobile}>
          <Row xs={1} sm={1} lg={2} className="g-4">
            <Col key={1}>
              <EventInvitation />
            </Col>
            <Col key={2}>
            <Card className={styles.registration}>          
                    <Card.Title>{t("Event.Registration.Title")}</Card.Title>
                    <Card.Body>
                      <EventRegistration></EventRegistration>
                    </Card.Body>
                </Card>            
            </Col>
          </Row>
        </Section>
        <EventInfoModal      
          show={show}
          onHide={() => setShow(false)}                
        />            
    </>
  );
}

export default EventInfoSection;