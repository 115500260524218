import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Row from 'react-bootstrap/Row';
import * as formik from 'formik';
import * as yup from 'yup';
import PrivacyNotice from './PrivacyNotice';
import { RequestInformationModel } from '../../../models/RegistrationModel';
import { requestInfo } from '../../../services/emailService';
import emailjs from "@emailjs/browser";
import { useEffect, useState } from 'react';
import { EmailJS } from '../../../constants/services';
import { FormikProps } from 'formik';
import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

function RequestInformation() {
  const { Formik } = formik;
  const { t } = useTranslation();  
  const [loading, setLoading] = useState(false);    
  const [formikRef] = useState<React.RefObject<FormikProps<RequestInformationModel>>>(React.createRef<FormikProps<RequestInformationModel>>());
  const phoneRegEx = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
  const schema = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().email().required(),
    telephone: yup.string().matches(phoneRegEx).required(),
    country: yup.string().required(),
    zip: yup.string().required(),
    specialty: yup.string().required(),
    comments: yup.string(),
    terms: yup.bool().required().oneOf([true], t("ContactUs.Validations.Terms")),
  });
  const initialValues : RequestInformationModel = {
    identifier:window.location.href,
    firstName: '',
    lastName: '',
    email: '',
    telephone: '',
    country: '',
    zip: '',
    specialty: '',
    comments: '',
    terms: false,
  }
  async function onSubmit(): Promise<void> {  
    try {
      setLoading(true);
      await requestInfo(formikRef.current?.values);
    } catch (err) {
      console.error(err);
    }
    finally {
      setLoading(false);
    }
  }

  useEffect(() => emailjs.init(EmailJS.PublicKey), []);
  
  return (
    <Formik
      innerRef={formikRef as never}
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {({ handleSubmit, handleChange, values, touched, errors }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Row className="mb-2">
            <Form.Group
              as={Col}
              md="6"
              controlId="validationFirstName"
              className="position-relative"
            >
              <FloatingLabel
                controlId="floatingFirstName"              
                label={t("ContactUs.Fields.FirstName")}
                className="mb-2"
              >
                <Form.Control
                  type="text"
                  name="firstName"
                  placeholder={t("ContactUs.Fields.FirstName")}             
                  value={values.firstName}
                  onChange={handleChange}
                  isInvalid={!!errors.firstName}
                  isValid={touched.firstName && !errors.firstName}
                  maxLength={50}
                />
              </FloatingLabel>              
            </Form.Group>
            <Form.Group
              as={Col}
              md="6"
              controlId="validationLastName"
              className="position-relative"
            >
              <FloatingLabel
                controlId="floatingLastName"              
                label={t("ContactUs.Fields.LastName")}
                className="mb-2"
              >
                <Form.Control
                  type="text"
                  name="lastName"
                  placeholder={t("ContactUs.Fields.LastName")}             
                  value={values.lastName}
                  onChange={handleChange}
                  isInvalid={!!errors.lastName}
                  isValid={touched.lastName && !errors.lastName}
                  maxLength={100}
                />
              </FloatingLabel>              
            </Form.Group>
          </Row>
          <Row className="mb-2">
            <Form.Group
              as={Col}
              md="6"
              controlId="validationEmail"
              className="position-relative"
            >
              <FloatingLabel
                controlId="floatingEmail"              
                label={t("ContactUs.Fields.Email")}
                className="mb-2"
              >
                <Form.Control
                  type="email"
                  name="email"
                  placeholder={t("ContactUs.Fields.Email")}             
                  value={values.email}
                  onChange={handleChange}
                  isInvalid={!!errors.email}
                  isValid={touched.email && !errors.email}
                  maxLength={100}
                />
              </FloatingLabel>              
            </Form.Group>
            <Form.Group
              as={Col}
              md="6"
              controlId="validationTelephone"
              className="position-relative"
            >
              <FloatingLabel
                controlId="floatingTelephone"              
                label={t("ContactUs.Fields.Telephone")}
                className="mb-2"
              >
                <Form.Control
                  type="text"
                  name="telephone"
                  placeholder={t("ContactUs.Fields.Telephone")}             
                  value={values.telephone}
                  onChange={handleChange}
                  isInvalid={!!errors.telephone}
                  isValid={touched.telephone && !errors.telephone}
                  maxLength={20}
                />
              </FloatingLabel>              
            </Form.Group>
          </Row>
          <Row className="mb-2">
            <Form.Group
              as={Col}
              md="8"
              controlId="validationCountry"
              className="position-relative"
            >
              <FloatingLabel
                controlId="floatingCountry"              
                label={t("ContactUs.Fields.Country")}
                className="mb-2"
              >
                <Form.Control
                  type="text"
                  name="country"
                  placeholder={t("ContactUs.Fields.Country")}             
                  value={values.country}
                  onChange={handleChange}
                  isInvalid={!!errors.country}
                  isValid={touched.country && !errors.country}
                  maxLength={50}
                />
              </FloatingLabel>              
            </Form.Group>
            <Form.Group
              as={Col}
              md="4"
              controlId="validationZip"
              className="position-relative"
            >
              <FloatingLabel
                controlId="floatingZip"              
                label={t("ContactUs.Fields.Zip")}
                className="mb-2"
              >
                <Form.Control
                  type="text"
                  name="zip"
                  placeholder={t("ContactUs.Fields.Zip")}             
                  value={values.zip}
                  onChange={handleChange}
                  isInvalid={!!errors.zip}
                  isValid={touched.zip && !errors.zip}
                  maxLength={10}
                />
              </FloatingLabel>              
            </Form.Group>
          </Row>         
          <Form.Group className="position-relative">
              <FloatingLabel
                controlId="floatingSpecialty"              
                label={t("ContactUs.Fields.Specialty")}
                className="mb-2"
              >
                <Form.Select
                  name="specialty"
                  value={values.specialty}
                  onChange={handleChange}
                  isInvalid={!!errors.specialty}
                  isValid={touched.specialty && !errors.specialty}
                >
                  <option value="">{t("ContactUs.Placeholders.Specialty")}</option>
                  <option value={t("Specialties.Cardiology.Title")}>{t("Specialties.Cardiology.Title")}</option>
                  <option value={t("Specialties.Neurosurgery.Title")}>{t("Specialties.Neurosurgery.Title")}</option>
                  <option value={t("Specialties.Gastrosurgery.Title")}>{t("Specialties.Gastrosurgery.Title")}</option>
                  <option value={t("Specialties.Traumatology.Title")}>{t("Specialties.Traumatology.Title")}</option>
                  <option value={t("Specialties.Urology.Title")}>{t("Specialties.Urology.Title")}</option>
                  <option value={t("Specialties.Oncosurgery.Title")}>{t("Specialties.Oncosurgery.Title")}</option>
                  <option value={t("Specialties.VascularInterventionism.Title")}>{t("Specialties.VascularInterventionism.Title")}</option>
                </Form.Select>
              </FloatingLabel>              
            </Form.Group>
          <Row>
            <Form.Group
              as={Col}
              md="12"
              controlId="validationComments"
              className="position-relative"
            >
              <FloatingLabel
                controlId="floatingComments"              
                label={t("ContactUs.Fields.Comments")}
                className="mb-2"
              >
                <Form.Control
                  as="textarea"
                  name="comments"
                  placeholder={t("ContactUs.Fields.Comments")}             
                  value={values.comments}
                  onChange={handleChange}
                  isInvalid={!!errors.comments}
                  isValid={touched.comments && !errors.comments}
                  style={{ height: '75px' }}     
                  maxLength={500}             
                />
              </FloatingLabel>              
            </Form.Group>
          </Row>
          <Form.Group className="position-relative mb-2">
            <Form.Check
              required
              name="terms"
              className='d-inline'
              onChange={handleChange}
              isInvalid={!!errors.terms}
              isValid={touched.terms && !errors.terms}
              id="validationTerms"
            >              
            </Form.Check>
            <span>
              {t("ContactUs.Fields.Terms")}
            </span>
            <PrivacyNotice linkText={t("CopyRight.PrivacyNotice.Title")}/>
          </Form.Group >
          <div>
            <Button variant='success' disabled={loading} type="submit">{t("Actions.RequestInfo")}
            {loading && <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"              
            />}
            </Button>            
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default RequestInformation;