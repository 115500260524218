import { ReactElement, ReactNode } from 'react';
import Container from 'react-bootstrap/Container';
import styles from "./Section.module.scss";

interface Properties {
    id?: string;
    title?: string;
    className?: string;
    children: ReactNode;
}

 function Section(props: Properties): ReactElement {
    const {id, title, className, children} = props;
  return (
    <section id= {id} className={`${styles.section} ${className ?? ""}`}>
        {title && <h2 className={styles.title}>{title}</h2>}
        <Container fluid >
            {children}
        </Container>
    </section>
  );
}

export default Section;