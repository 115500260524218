import { useTranslation } from 'react-i18next';
import Carousel from 'react-bootstrap/Carousel';
import Section from '../common/Section';
import styles from "./TreatmentsSection.module.scss";
import transgenderSurgery from '../../../assets/images/treatment-transgender-surgery.jpg';
import deepBrainStimulation from '../../../assets/images/treatment-deep-brain-stimulation.jpg';
import neuroStimulator from '../../../assets/images/treatment-spinal-neurostimulator.jpg';
import prostateEmbolization from '../../../assets/images/treatment-prostate-embolization.jpg';
import tumorsEmbolization from '../../../assets/images/treatment-tumors-embolization.jpg';
import { Card } from 'react-bootstrap';


function TreatmentSection() {
    const { t } = useTranslation();
  return (
    <Section id='treatments' title={t("Treatments.Title")} className={styles.treatment}>
        <Card>
            <Carousel>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={deepBrainStimulation}
                    alt={t("Treatments.DeepBrainStimulation.Title")}
                    />
                    <Carousel.Caption>
                        <h3>{t("Treatments.DeepBrainStimulation.Title")}</h3>
                        <h5>{t("Specialties.Neurosurgery.Title")}</h5>
                        <p>{t("Treatments.DeepBrainStimulation.Content")}</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={neuroStimulator}
                    alt={t("Treatments.SpinalNeurostimulator.Title")}
                    />
                    <Carousel.Caption>
                        <h3>{t("Treatments.SpinalNeurostimulator.Title")}</h3>
                        <h5>{t("Specialties.Neurosurgery.Title")}</h5>
                        <p>{t("Treatments.SpinalNeurostimulator.Content")}</p>
                    </Carousel.Caption>
                </Carousel.Item>
                {/*<Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={transgenderSurgery}
                    alt={t("Treatments.TransgenderSurgery.Title")}
                    />
                    <Carousel.Caption>
                        <h3>{t("Treatments.TransgenderSurgery.Title")}</h3>
                        <h5>{t("Specialties.Urology.Title")}</h5>
                        <p>{t("Treatments.TransgenderSurgery.Content")}</p>
                    </Carousel.Caption>
                </Carousel.Item>*/}
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={prostateEmbolization}
                    alt={t("Treatments.ProstateEmbolization.Title")}
                    />
                    <Carousel.Caption>
                        <h3>{t("Treatments.ProstateEmbolization.Title")}</h3>
                        <h5>{t("Specialties.Urology.Title")}</h5>
                        <p>{t("Treatments.ProstateEmbolization.Content")}</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={tumorsEmbolization}
                    alt={t("Treatments.TumorsEmbolization.Title")}
                    />
                    <Carousel.Caption>
                        <h3>{t("Treatments.TumorsEmbolization.Title")}</h3>
                        <h5>{t("Specialties.VascularInterventionism.Title")}</h5>
                        <p>{t("Treatments.TumorsEmbolization.Content")}</p>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </Card>
    </Section>
  );
}

export default TreatmentSection;