import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from '../../../assets/images/company-logo.jpg';
import styles from "./Header.module.scss";
import LanguageToogle from './LanguageToggle';
import { faFacebook, faInstagram, faTiktok, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Offcanvas } from 'react-bootstrap';

function Header() {
  const { t } = useTranslation();  
  const expand = "lg";
  return (
    <Navbar expand="lg" className="bg-body-tertiary" sticky="top" collapseOnSelect={true}>
      <Container fluid className={styles.header}>
        <img src={logo} className={styles.logo} />
        {/*<Navbar.Text className={styles.brand}>{t("Brand")}</Navbar.Text>*/}
        <Navbar.Brand className={styles.brand} href="#">{t("Brand")}</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-${expand}`}
          aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
          placement="end"
          
        >
          <Offcanvas.Header closeButton>
            <img src={logo} className={styles.logo} />
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
            {t("Brand")}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>        
            <Nav
              className="me-auto my-2 my-lg-0"
            >
              <Nav.Link href="#aboutUs">{t("AboutUs.Title")}</Nav.Link>
              <NavDropdown title={t("Specialties.Title")} id="navbarScrollingDropdown">
                <NavDropdown.Item href="#specialties">{t("Specialties.VascularInterventionism.Title")}</NavDropdown.Item>
                <NavDropdown.Item href="#specialties">{t("Specialties.Cardiology.Title")}</NavDropdown.Item>
                <NavDropdown.Item href="#specialties">{t("Specialties.Neurosurgery.Title")}</NavDropdown.Item>
                <NavDropdown.Item href="#specialties">{t("Specialties.Gastrosurgery.Title")}</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#specialties">{t("Specialties.Traumatology.Title")}</NavDropdown.Item>
                <NavDropdown.Item href="#specialties">{t("Specialties.Urology.Title")}</NavDropdown.Item>
                <NavDropdown.Item href="#specialties">{t("Specialties.Oncosurgery.Title")}</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="#faqs">{t("FAQ.Title")}</Nav.Link>
              <Nav.Link href="#contactUs">{t("ContactUs.Title")}</Nav.Link>
              <Nav.Link data-title='Facebook' target="_blank" href="https://www.facebook.com/profile.php?id=61565194287870&mibextid=kFxxJD">
                <FontAwesomeIcon icon={faFacebook} />            
              </Nav.Link>
              <Nav.Link data-title='Instagram' target="_blank" href="https://www.instagram.com/vidaconturismomedico?igsh=MWkwdGtlNHM4ejNvcw==">
                <FontAwesomeIcon icon={faInstagram} />            
              </Nav.Link>
              <Nav.Link data-title='TikTok' target="_blank" href="https://www.tiktok.com/@vida.con.turismo?_t=8pNLnh3ro9d&_r=1">
                <FontAwesomeIcon icon={faTiktok} />            
              </Nav.Link>
              <Nav.Link data-title='Youtube' target="_blank" href="https://youtube.com/@vidaconturismomedico?si=h29MLGkCxa17qqrO">
                <FontAwesomeIcon icon={faYoutube} />            
              </Nav.Link>
            </Nav>
            <LanguageToogle></LanguageToogle>   
          </Offcanvas.Body>
          </Navbar.Offcanvas>              
      </Container>
    </Navbar>
  );
}

export default Header;