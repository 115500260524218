import { useTranslation } from 'react-i18next';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Section from '../common/Section';
import vascularInterventionism from '../../../assets/images/specialty-vascular-interventionism.jpg';
import cardiology from '../../../assets/images/specialty-cardiology.jpg';
import neurology from '../../../assets/images/specialty-neurology.jpg';
import gastrosurgery from '../../../assets/images/specialty-gastrosurgery.jpg';
import traumatology from '../../../assets/images/specialty-traumatology.jpg';
import urology from '../../../assets/images/specialty-urology.jpg';
import oncology from '../../../assets/images/specialty-oncology.jpg';
import { Card, CardSubtitle, CardText, CardTitle } from 'react-bootstrap';
import styles from "./SpecialtiesSection.module.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck} from "@fortawesome/free-solid-svg-icons";

function SpecialtiesSection() {
    const { t } = useTranslation();
  return (
    <Section id='specialties' className={styles.specialties} title={t("Specialties.Title")}>
        <Tab.Container id="left-tabs-example" defaultActiveKey="1">
        <Row>
            <Col sm={3}>
            <Nav variant="pills" className="flex-column">
                <Nav.Item>
                    <Nav.Link eventKey="1">{t("Specialties.VascularInterventionism.Title")}</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="2">{t("Specialties.Cardiology.Title")}</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="3">{t("Specialties.Neurosurgery.Title")}</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="4">{t("Specialties.Gastrosurgery.Title")}</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="5">{t("Specialties.Traumatology.Title")}</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="6">{t("Specialties.Urology.Title")}</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="7">{t("Specialties.Oncosurgery.Title")}</Nav.Link>
                </Nav.Item>
            </Nav> 
            </Col>
            <Col sm={9}>
            <Tab.Content>                
                <Tab.Pane eventKey="1">
                    <section id='specialties-1'>
                    <Card className={styles.specialties}>
                        <Card.Img src={vascularInterventionism}  />
                        <Card.ImgOverlay>
                            <Row>
                                <CardText className={styles.specialtyTitle}>
                                    {t("Specialties.VascularInterventionism.Title")}                         
                                </CardText>
                            </Row>
                            <CardText className={styles.treatmentsItem}>
                                <FontAwesomeIcon icon={faCircleCheck} />                    
                                {t("Specialties.VascularInterventionism.Treatments.ProstateEmbolization")}                         
                            </CardText>
                            <CardText className={styles.treatmentsItem}>
                                <FontAwesomeIcon icon={faCircleCheck} />                    
                                {t("Specialties.VascularInterventionism.Treatments.EmbolizationBenignAndMalignantTumors")}                         
                            </CardText>
                            <CardText className={styles.treatmentsItem}>
                                <FontAwesomeIcon icon={faCircleCheck} />                    
                                {t("Specialties.VascularInterventionism.Treatments.EmbolizationUterineFibroids")}                         
                            </CardText>
                            <CardText className={styles.treatmentsItem}>
                                <FontAwesomeIcon icon={faCircleCheck} />                    
                                {t("Specialties.VascularInterventionism.Treatments.ManagementOfPeripheralArterialStenosis")}                         
                            </CardText>
                            <CardText className={styles.treatmentsItem}>
                                <FontAwesomeIcon icon={faCircleCheck} />                    
                                {t("Specialties.VascularInterventionism.Treatments.InstallationOfVenaCavaFilters")}                         
                            </CardText>
                            <CardText className={styles.treatmentsItem}>
                                <FontAwesomeIcon icon={faCircleCheck} />                    
                                {t("Specialties.VascularInterventionism.Treatments.DrainageOfAbscesses")}                         
                            </CardText>
                            <CardText className={styles.treatmentsItem}>
                                <FontAwesomeIcon icon={faCircleCheck} />                    
                                {t("Specialties.VascularInterventionism.Treatments.EmbolizationOfAorticAneurysms")}                         
                            </CardText>
                            <CardText className={styles.treatmentsItem}>
                                <FontAwesomeIcon icon={faCircleCheck} />                    
                                {t("Specialties.VascularInterventionism.Treatments.GastricBypass")}                         
                            </CardText>                        
                        </Card.ImgOverlay>
                    </Card>
                    </section>
                </Tab.Pane>
                <Tab.Pane eventKey="2">
                    <Card className={styles.specialties}>
                        <Card.Img src={cardiology}/>
                        <Card.ImgOverlay>
                            <Row>
                                <CardText className={styles.specialtyTitle}>
                                    {t("Specialties.Cardiology.Title")}                         
                                </CardText>
                            </Row>
                            <CardText className={styles.treatmentsItem}>
                                <FontAwesomeIcon icon={faCircleCheck} />                    
                                {t("Specialties.Cardiology.Treatments.DiagnosticAndTherapeuticCardiacCatheterization")}                         
                            </CardText>
                            <CardText className={styles.treatmentsItem}>
                                <FontAwesomeIcon icon={faCircleCheck} />                    
                                {t("Specialties.Cardiology.Treatments.ProstheticValveImplantationByCatheterization")}                         
                            </CardText>
                            <CardText className={styles.treatmentsItem}>
                                <FontAwesomeIcon icon={faCircleCheck} />                    
                                {t("Specialties.Cardiology.Treatments.TreatmentOfAneurysms")}                         
                            </CardText>
                        </Card.ImgOverlay>
                    </Card>
                </Tab.Pane>
                <Tab.Pane eventKey="3">
                    <Card className={styles.specialties}>
                        <Card.Img src={neurology} />
                        <Card.ImgOverlay>
                            <Row>
                                <CardText className={styles.specialtyTitle}>
                                    {t("Specialties.Neurosurgery.Title")}                         
                                </CardText>
                            </Row>
                            <Row>
                                <Col xs>
                                    <CardText className={styles.specialtySubtitle}>
                                        {t("Specialties.Neurosurgery.Subspecialties.NeuroRadiointervention.Title")}                         
                                    </CardText>
                                </Col>
                                <Col xs>
                                    <CardText className={styles.specialtySubtitle}>
                                        {t("Specialties.Neurosurgery.Subspecialties.FunctionalNeurosurgery.Title")}                         
                                    </CardText>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs>
                                    <CardText className={styles.treatmentsItem}>
                                        <FontAwesomeIcon icon={faCircleCheck} />                    
                                        {t("Specialties.Neurosurgery.Subspecialties.NeuroRadiointervention.Treatments.Fistulas")}                         
                                    </CardText>
                                </Col>
                                <Col xs>
                                    <CardText className={styles.treatmentsItem}>
                                        <FontAwesomeIcon icon={faCircleCheck} />                    
                                        {t("Specialties.Neurosurgery.Subspecialties.FunctionalNeurosurgery.Treatments.EpilepsySurgery")}                         
                                    </CardText>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs>
                                    <CardText className={styles.treatmentsItem}>
                                        <FontAwesomeIcon icon={faCircleCheck} />                    
                                        {t("Specialties.Neurosurgery.Subspecialties.NeuroRadiointervention.Treatments.AneurysmEmbolization")}                         
                                    </CardText>
                                </Col>
                                <Col xs>
                                    <CardText className={styles.treatmentsItem}>
                                        <FontAwesomeIcon icon={faCircleCheck} />                    
                                        {t("Specialties.Neurosurgery.Subspecialties.FunctionalNeurosurgery.Treatments.SurgeryForMovementDisorders")}                         
                                    </CardText>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs>
                                    <CardText className={styles.treatmentsItem}>
                                        <FontAwesomeIcon icon={faCircleCheck} />                    
                                        {t("Specialties.Neurosurgery.Subspecialties.NeuroRadiointervention.Treatments.Stenosis")}                         
                                    </CardText>
                                </Col>
                                <Col xs>
                                    <CardText className={styles.treatmentsItem}>
                                        <FontAwesomeIcon icon={faCircleCheck} />                    
                                        {t("Specialties.Neurosurgery.Subspecialties.FunctionalNeurosurgery.Treatments.PsychiatricIllnesses")}                         
                                    </CardText>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs>
                                    <CardText className={styles.treatmentsItem}>
                                        <FontAwesomeIcon icon={faCircleCheck} />                    
                                        {t("Specialties.Neurosurgery.Subspecialties.NeuroRadiointervention.Treatments.CongenitalVascularMalformations")}                         
                                    </CardText>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs>
                                    <CardText className={styles.treatmentsItem}>
                                        <FontAwesomeIcon icon={faCircleCheck} />                    
                                        {t("Specialties.Neurosurgery.Subspecialties.NeuroRadiointervention.Treatments.IntracranialHypertension")}                         
                                    </CardText>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs>
                                    <CardText className={styles.treatmentsItem}>
                                        <FontAwesomeIcon icon={faCircleCheck} />                    
                                        {t("Specialties.Neurosurgery.Subspecialties.NeuroRadiointervention.Treatments.PreviousStudyForEpilepsySurgery")}                         
                                    </CardText>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs>
                                    <CardText className={styles.treatmentsItem}>
                                        <FontAwesomeIcon icon={faCircleCheck} />                    
                                        {t("Specialties.Neurosurgery.Subspecialties.NeuroRadiointervention.Treatments.EmbolizationOfTumorsCNS")}                         
                                    </CardText>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs>
                                    <CardText className={styles.treatmentsItem}>
                                        <FontAwesomeIcon icon={faCircleCheck} />                    
                                        {t("Specialties.Neurosurgery.Subspecialties.NeuroRadiointervention.Treatments.Thrombosis")}                         
                                    </CardText>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs>
                                    <CardText className={styles.treatmentsItem}>
                                        <FontAwesomeIcon icon={faCircleCheck} />                    
                                        {t("Specialties.Neurosurgery.Subspecialties.NeuroRadiointervention.Treatments.DiagnosticCerebralArteriograms")}                         
                                    </CardText>
                                </Col>
                            </Row>
                        </Card.ImgOverlay>
                    </Card>
                </Tab.Pane>
                <Tab.Pane eventKey="4">
                    <Card className={styles.specialties}>
                        <Card.Img src={gastrosurgery}  />
                        <Card.ImgOverlay>
                            <Row>
                                <CardText className={styles.specialtyTitle}>
                                    {t("Specialties.Gastrosurgery.Title")}                         
                                </CardText>
                            </Row>
                            <CardText className={styles.treatmentsItem}>
                                <FontAwesomeIcon icon={faCircleCheck} />                    
                                {t("Specialties.Gastrosurgery.Treatments.AbdominalHernias")}                         
                            </CardText>
                            <CardText className={styles.treatmentsItem}>
                                <FontAwesomeIcon icon={faCircleCheck} />                    
                                {t("Specialties.Gastrosurgery.Treatments.HiatalHernias")}                         
                            </CardText>
                            <CardText className={styles.treatmentsItem}>
                                <FontAwesomeIcon icon={faCircleCheck} />                    
                                {t("Specialties.Gastrosurgery.Treatments.BariatricSurgery")}                         
                            </CardText>
                            <CardText className={styles.treatmentsItem}>
                                <FontAwesomeIcon icon={faCircleCheck} />                    
                                {t("Specialties.Gastrosurgery.Treatments.Cholecystectomy")}                         
                            </CardText>
                            <CardText className={styles.treatmentsItem}>
                                <FontAwesomeIcon icon={faCircleCheck} />                    
                                {t("Specialties.Gastrosurgery.Treatments.Panendoscopy")}                         
                            </CardText>
                            <CardText className={styles.treatmentsItem}>
                                <FontAwesomeIcon icon={faCircleCheck} />                    
                                {t("Specialties.Gastrosurgery.Treatments.Colonoscopy")}                         
                            </CardText>
                            <CardText className={styles.treatmentsItem}>
                                <FontAwesomeIcon icon={faCircleCheck} />                    
                                {t("Specialties.Gastrosurgery.Treatments.ERCP")}                         
                            </CardText>
                            <CardText className={styles.treatmentsItem}>
                                <FontAwesomeIcon icon={faCircleCheck} />                    
                                {t("Specialties.Gastrosurgery.Treatments.CapsuleEndoscopyEnteroscopy")}                         
                            </CardText>
                        </Card.ImgOverlay>
                    </Card>
                </Tab.Pane>                                
                <Tab.Pane eventKey="5">
                    <Card className={styles.specialties}>
                        <Card.Img src={traumatology}  />
                        <Card.ImgOverlay>
                            <Row>
                                <CardText className={styles.specialtyTitle}>
                                    {t("Specialties.Traumatology.Title")}                         
                                </CardText>
                            </Row>                            
                            <CardText className={styles.treatmentsItem}>
                                <FontAwesomeIcon icon={faCircleCheck} />                    
                                {t("Specialties.Traumatology.Treatments.ShoulderSurgery")}                         
                            </CardText>
                            <CardText className={styles.treatmentsItem}>
                                <FontAwesomeIcon icon={faCircleCheck} />                    
                                {t("Specialties.Traumatology.Treatments.ColumnSurgery")}                         
                            </CardText>
                            <CardText className={styles.treatmentsItem}>
                                <FontAwesomeIcon icon={faCircleCheck} />                    
                                {t("Specialties.Traumatology.Treatments.HipSurgery")}                         
                            </CardText>
                            <CardText className={styles.treatmentsItem}>
                                <FontAwesomeIcon icon={faCircleCheck} />                    
                                {t("Specialties.Traumatology.Treatments.KneeSurgery")}                         
                            </CardText>
                            <CardText className={styles.treatmentsItem}>
                                <FontAwesomeIcon icon={faCircleCheck} />                    
                                {t("Specialties.Traumatology.Treatments.DiagnosticAndTherapeuticArthroscopy")}                         
                            </CardText>
                        </Card.ImgOverlay>
                    </Card>
                </Tab.Pane>                
                <Tab.Pane eventKey="6">
                    <Card className={styles.specialties}>
                        <Card.Img src={urology}  />
                        <Card.ImgOverlay>
                            <Row>
                                <CardText className={styles.specialtyTitle}>
                                    {t("Specialties.Urology.Title")}                         
                                </CardText>
                            </Row>                            
                        <CardText className={styles.treatmentsItem}>
                            <FontAwesomeIcon icon={faCircleCheck} />                    
                            {t("Specialties.Urology.Treatments.ProstaticGrowthHyperplasia")}                         
                        </CardText>
                        <CardText className={styles.treatmentsItem}>
                            <FontAwesomeIcon icon={faCircleCheck} />                    
                            {t("Specialties.Urology.Treatments.UrinaryTractCalculations")}                         
                        </CardText>
                        <CardText className={styles.treatmentsItem}>
                            <FontAwesomeIcon icon={faCircleCheck} />                    
                            {t("Specialties.Urology.Treatments.UrinaryTractCancer")}                         
                        </CardText>
                        <CardText className={styles.treatmentsItem}>
                            <FontAwesomeIcon icon={faCircleCheck} />                    
                            {t("Specialties.Urology.Treatments.DiseasesOfTheTesticles")}                         
                        </CardText>
                        <CardText className={styles.treatmentsItem}>
                            <FontAwesomeIcon icon={faCircleCheck} />                    
                            {t("Specialties.Urology.Treatments.PhimosisRedundantForeskin")}                         
                        </CardText>
                        <CardText className={styles.treatmentsItem}>
                            <FontAwesomeIcon icon={faCircleCheck} />                    
                            {t("Specialties.Urology.Treatments.StenosisOfTheUrethra")}                         
                        </CardText>
                        <CardText className={styles.treatmentsItem}>
                            <FontAwesomeIcon icon={faCircleCheck} />                    
                            {t("Specialties.Urology.Treatments.KidneyCysts")}                         
                        </CardText>
                        <CardText className={styles.treatmentsItem}>
                            <FontAwesomeIcon icon={faCircleCheck} />                    
                            {t("Specialties.Urology.Treatments.UrinaryFistulas")}                         
                        </CardText>
                        <CardText className={styles.treatmentsItem}>
                            <FontAwesomeIcon icon={faCircleCheck} />                    
                            {t("Specialties.Urology.Treatments.Cryptorchidism")}                         
                        </CardText>
                        <CardText className={styles.treatmentsItem}>
                            <FontAwesomeIcon icon={faCircleCheck} />                    
                            {t("Specialties.Urology.Treatments.TesticularTorsion")}                         
                        </CardText>
                        <CardText className={styles.treatmentsItem}>
                            <FontAwesomeIcon icon={faCircleCheck} />                    
                            {t("Specialties.Urology.Treatments.Ureterocele")}                         
                        </CardText>
                        <CardText className={styles.treatmentsItem}>
                            <FontAwesomeIcon icon={faCircleCheck} />                    
                            {t("Specialties.Urology.Treatments.UreteroPielicUnionStenosis")}                         
                        </CardText>
                        </Card.ImgOverlay>
                    </Card>
                </Tab.Pane>                
                <Tab.Pane eventKey="7">
                    <Card className={styles.specialties}>
                        <Card.Img src={oncology}  />
                        <Card.ImgOverlay>
                            <Row>
                                <CardText className={styles.specialtyTitle}>
                                    {t("Specialties.Oncosurgery.Title")}                         
                                </CardText>
                            </Row>
                            <Row>
                                <Col xs>
                                    <CardText className={styles.specialtySubtitle}>
                                        {t("Specialties.Oncosurgery.TypesOfSurgery.Title")}                         
                                    </CardText>
                                </Col>
                                <Col xs>
                                    <CardText className={styles.specialtySubtitle}>
                                        {t("Specialties.Oncosurgery.TypeOfSolidTumors.Title")}                         
                                    </CardText>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs>
                                    <CardText className={styles.treatmentsItem}>
                                        <FontAwesomeIcon icon={faCircleCheck} />                    
                                        {t("Specialties.Oncosurgery.TypesOfSurgery.Types.Diagnosis")}                         
                                    </CardText>
                                </Col>
                                <Col xs>
                                    <CardText className={styles.treatmentsItem}>
                                        <FontAwesomeIcon icon={faCircleCheck} />                    
                                        {t("Specialties.Oncosurgery.TypeOfSolidTumors.Types.Breast")}                         
                                    </CardText>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs>
                                    <CardText className={styles.treatmentsItem}>
                                        <FontAwesomeIcon icon={faCircleCheck} />                    
                                        {t("Specialties.Oncosurgery.TypesOfSurgery.Types.Staging")}                         
                                    </CardText>
                                </Col>
                                <Col xs>
                                    <CardText className={styles.treatmentsItem}>
                                        <FontAwesomeIcon icon={faCircleCheck} />                    
                                        {t("Specialties.Oncosurgery.TypeOfSolidTumors.Types.Lung")}                         
                                    </CardText>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs>
                                    <CardText className={styles.treatmentsItem}>
                                        <FontAwesomeIcon icon={faCircleCheck} />                    
                                        {t("Specialties.Oncosurgery.TypesOfSurgery.Types.TumorRemoval")}                         
                                    </CardText>
                                </Col>
                                <Col xs>
                                    <CardText className={styles.treatmentsItem}>
                                        <FontAwesomeIcon icon={faCircleCheck} />                    
                                        {t("Specialties.Oncosurgery.TypeOfSolidTumors.Types.Prostate")}                         
                                    </CardText>
                                </Col>
                            </Row>
                            <Row>
                            <Col xs>
                                    <CardText className={styles.treatmentsItem}>
                                        <FontAwesomeIcon icon={faCircleCheck} />                    
                                        {t("Specialties.Oncosurgery.TypesOfSurgery.Types.Cytoreduction")}                         
                                    </CardText>
                                </Col>
                                <Col xs>
                                    <CardText className={styles.treatmentsItem}>
                                        <FontAwesomeIcon icon={faCircleCheck} />                    
                                        {t("Specialties.Oncosurgery.TypeOfSolidTumors.Types.Colon")}                         
                                    </CardText>
                                </Col>
                            </Row>
                            <Row>
                            <Col xs>
                                    <CardText className={styles.treatmentsItem}>
                                        <FontAwesomeIcon icon={faCircleCheck} />                    
                                        {t("Specialties.Oncosurgery.TypesOfSurgery.Types.PalliativeSurgery")}                         
                                    </CardText>
                                </Col>
                                <Col xs>
                                    <CardText className={styles.treatmentsItem}>
                                        <FontAwesomeIcon icon={faCircleCheck} />                    
                                        {t("Specialties.Oncosurgery.TypeOfSolidTumors.Types.Pancreas")}                         
                                    </CardText>
                                </Col>
                            </Row>
                            <Row>
                            <Col xs>
                                    <CardText className={styles.treatmentsItem}>
                                        <FontAwesomeIcon icon={faCircleCheck} />                    
                                        {t("Specialties.Oncosurgery.TypesOfSurgery.Types.Prevention")}                         
                                    </CardText>
                                </Col>
                                <Col xs>
                                    <CardText className={styles.treatmentsItem}>
                                        <FontAwesomeIcon icon={faCircleCheck} />                    
                                        {t("Specialties.Oncosurgery.TypeOfSolidTumors.Types.LiverAndBileDucts")}                         
                                    </CardText>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs />
                                <Col xs>
                                    <CardText className={styles.treatmentsItem}>
                                        <FontAwesomeIcon icon={faCircleCheck} />                    
                                        {t("Specialties.Oncosurgery.TypeOfSolidTumors.Types.UterusAndOvaries")}                         
                                    </CardText>
                                </Col>
                            </Row>
                        </Card.ImgOverlay>
                    </Card>
                </Tab.Pane>                
            </Tab.Content>
            </Col>
        </Row>
        </Tab.Container>
    </Section>
  );
}

export default SpecialtiesSection;