import { useTranslation } from 'react-i18next';
import Card from 'react-bootstrap/Card';
import Section from '../common/Section';
import styles from "./ProcessSection.module.scss";
import { CardGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentMedical, faCalendarPlus, faHospital, faHospitalUser, faBedPulse, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import FlipCard from '../common/FlipCard';


interface StepProps {
    step: number;
    icon: IconDefinition;
    sectionId?: string;
}


function GetFlipCard( props: StepProps )
{
    const { t } = useTranslation();
    const {step, icon, sectionId} = props;
    return <FlipCard 
            id={step.toString()}
            key={step.toString()}
            className={styles.stepFlipCard}
            frontCard={
                <div className={`${styles.stepFront} ${styles[`stepFront${step}`]}`}>
                    <FontAwesomeIcon icon={icon} />                                    
                    <h5>{t(`Process.Steps.${step}.Title`)}</h5>
                </div>
            }
            backCard={
                <div className={`${styles.stepBack} ${styles[`stepBack${step}`]}`}>
                    <p>
                        {t(`Process.Steps.${step}.Content`)}
                        {sectionId && <a href={sectionId}>
                            <FontAwesomeIcon icon={icon} />                                    
                            {t(`Process.Steps.${step}.Link`)}
                        </a>}                
                    </p>
                </div>
            }
        />
}
function ProcessSection() {
    const { t } = useTranslation();
    const steps : StepProps[] = [
        {
            step: 1,
            icon: faCommentMedical,
            sectionId:'#contactUs',
        },
        {
            step: 2,
            icon: faCalendarPlus,
            sectionId:'#specialties',
        },
        {
            step: 3,
            icon: faHospital,
            sectionId:'#destinations',
        },
        {
            step: 4,
            icon: faBedPulse,
            sectionId:'#faqs',
        },
        {
            step: 5,
            icon: faHospitalUser,
            //sectionId:'#medicalSpecialists',
        }
    ]
  return (
    <Section title={t("Process.Title")}>
        <CardGroup >   
            {steps.map((step) => (
                GetFlipCard(step)
            ))}        
        </CardGroup>            
    </Section>
  );
}

export default ProcessSection;