import emailjs from "@emailjs/browser";
import { EmailJS } from "../constants/services";

const sendMessage =  async (serviceId:string, templateId: string, request:any): Promise<void> => {
    try {
        const sent = await emailjs.send(serviceId, templateId, request);
    } catch (err) {
        console.error(err);
    }
};

export const requestInfo =  async (request:any): Promise<void> => {
    try {
        const serviceId = EmailJS.ServiceId;
        const templateId = EmailJS.RequestInfoTemplateId;
        sendMessage(serviceId, templateId, request)
    } catch (err) {
        console.error(err);
    }
};

export const eventRegister =  async (request:any): Promise<void> => {
    try {
        const serviceId = EmailJS.ServiceId;
        const templateId = EmailJS.EventRegisterTemplateId;
        sendMessage(serviceId, templateId, request)
    } catch (err) {
        console.error(err);
    }
};