import { useTranslation } from 'react-i18next';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Section from '../common/Section';
import styles from "./AboutUs.module.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb, faHouseMedicalCircleCheck, faHandHoldingDollar, faArrowTrendUp } from "@fortawesome/free-solid-svg-icons";

function AboutUs() {
    const { t } = useTranslation();
  return (
    <Section id='aboutUs' title={t("AboutUs.Title")}>
        <Row xs={1} sm={1} lg={2} className="g-4">
            <Col key={1}>
                <Card className={styles.reason}>
                    <FontAwesomeIcon icon={faArrowTrendUp} />                                    
                    <Card.Body>
                    <h5>{t("AboutUs.Mission.Title")}</h5>
                    <Card.Text>
                        {t("AboutUs.Mission.Content")}
                    </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
            <Col key={2}>
                <Card className={styles.reason}>
                    <FontAwesomeIcon icon={faLightbulb} />                                    
                    <Card.Body>
                    <h5>{t("AboutUs.Vision.Title")}</h5>
                    <Card.Text>
                        {t("AboutUs.Vision.Content")}
                    </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </Section>
  );
}

export default AboutUs;