import Accordion from 'react-bootstrap/Accordion';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Section from '../common/Section';
import styles from "./FAQSection.module.scss";

 function FAQSection(): ReactElement {
    const { t } = useTranslation();
    const questions = [1,2,3,4,5];
    return (
        <Section id='faqs' title={t("FAQ.Title")} className={styles.faq}>
          <Accordion>
            {questions.map((question) => (
              <Accordion.Item key={question.toString()} eventKey={question.toString()}>
                <Accordion.Header>{t(`FAQ.Questions.${question}`)}</Accordion.Header>
                <Accordion.Body>
                  {t(`FAQ.Answers.${question}`)}                
                </Accordion.Body>
              </Accordion.Item>
            ))}        
          </Accordion>
        </Section>
    );
}

export default FAQSection;