import i18n from "i18next";
import Backend from 'i18next-http-backend';
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEn from "./translations/en/translations.json";
import translationEs from "./translations/es/translations.json";

const availableLanguages = [
  "es", "en"
];


const resources = {
  en: {
    translation: translationEn,
  },
  es: {
    translation: translationEs,
  },
};
const options = {
    order: ['querystring', 'navigator'],
    lookupQuerystring: 'lng'
}

i18n
.use(Backend)
.use(LanguageDetector)
.use(initReactI18next)
.init({
    detection: options,
    //detection: { order: ["path", "navigator"] },
    fallbackLng: "es",
    resources: resources,
    //lng: "ko",
    // ns: ['translation'],
    // defaultNS: "translation",
    debug: true,
    //keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });
  i18n.languages = ["es", "en"];
  export default i18n;