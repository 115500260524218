import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Section from '../common/Section';
import RequestInformation from '../common/RequestInformation';
import { Card } from 'react-bootstrap';
import styles from "./ContactUsSection.module.scss";

 function ContactUsSection(): ReactElement {
    const { t } = useTranslation();
    return (
        <Section id='contactUs'>
            <Card className={styles.contactUs}>
                <Card.Title>{t("ContactUs.Subtitle")}</Card.Title>
                <Card.Body>
                    <RequestInformation />
                </Card.Body>
            </Card>
        </Section>
    );
}

export default ContactUsSection;