import {useState} from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import Modal, { ModalProps } from 'react-bootstrap/Modal';
import { Card, NavLink } from 'react-bootstrap';
import styles from "./PrivacyNotice.module.scss";

interface Properties {
    linkText?: string;
    className?: string;
}

function PrivacyNoticeModal(props:ModalProps) {
  const { t } = useTranslation();  
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
      >
        <Modal.Header closeButton className={styles.header}>
          <Modal.Title id="contained-modal-title-vcenter">
          {t("CopyRight.PrivacyNotice.Title")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card className={styles.privacyNoticeContent}>
            <h4>{t("CopyRight.PrivacyNotice.Sections.1.Title")}</h4>
            <p>
              {t("CopyRight.PrivacyNotice.Sections.1.Content")}
            </p>
            <h4>{t("CopyRight.PrivacyNotice.Sections.2.Title")}</h4>
            <p>
              {t("CopyRight.PrivacyNotice.Sections.2.Content")}
            </p>
            <h4>{t("CopyRight.PrivacyNotice.Sections.3.Title")}</h4>
            <p>
              {t("CopyRight.PrivacyNotice.Sections.3.Content")}
            </p>            
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>{t("Actions.Close")}</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  function PrivacyNotice(props: Properties) {
    const [modalShow, setModalShow] = useState(false);
    const {linkText, className } = props;

    return (
      <>
        <a className={className ?? styles.link} onClick={() => setModalShow(true)}>
          {linkText}
        </a>
        <PrivacyNoticeModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      </>
    );
  }

  export default PrivacyNotice;
