import {ReactNode} from 'react';
import styles from "./FlipCard.module.scss";
import { Card } from 'react-bootstrap';

interface FlipCardProps {
    id: string;
    className?: string;
    frontCard: ReactNode;
    backCard: ReactNode;
}

function FlipCard(props: FlipCardProps) {
    const {id, className, frontCard, backCard} = props;
  return (
    <Card id={id} className={className  ?? ''}>
        <div className={styles.flipCard}>
            <div className={styles.flipCardInner}>
                <div className={styles.flipCardFront}>
                    {frontCard}
                </div>
                <div className={styles.flipCardBack}>
                    {backCard}
                </div>
            </div>
        </div>
    </Card>      
  );
}

export default FlipCard;