import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styles from "./Footer.module.scss";
import PrivacyNotice from './PrivacyNotice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneVolume, faEnvelope, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faInstagram, faTiktok, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { Nav, NavLink } from 'react-bootstrap';

function Footer() {
  const { t } = useTranslation();  
  return (
    <Container fluid className={styles.footer}>
      <Row xs={1} md={2}>
        <Col>
          <Row>
            <h3>{t("About.Title")}</h3>
          </Row>
          <Row>
            <Nav.Link href="#aboutUs">{t("AboutUs.Title")}</Nav.Link>
          </Row>
          <Row>
            <Nav.Link href="#specialties">{t("Specialties.Title")}</Nav.Link>
          </Row>
          <Row>
            <Nav.Link href="#faqs">{t("FAQ.Title")}</Nav.Link>
          </Row>
          <Row>
          <Nav.Link href="#contactUs">{t("ContactUs.Title")}</Nav.Link>
          </Row>
        </Col>
        <Col>
          <Row>
            <h3>{t("About.Contact")}</h3>
          </Row>
          <Row>
            <Col>
              <FontAwesomeIcon icon={faPhoneVolume} />                    
              <a className='ml-2' href={`tel:${t("ContactInfo.Telephone")}`}>{t("ContactInfo.Telephone")}</a>
            </Col>
          </Row>      
          <Row>
            <Col>
              <FontAwesomeIcon icon={faEnvelope} />                    
              <a href={`mailto:${t("ContactInfo.Email")}`}>{t("ContactInfo.Email")}</a>
            </Col>
          </Row>      
          <Row>
            <Col>
              <FontAwesomeIcon icon={faLocationDot} />                    
              <span>{t("ContactInfo.Address")}</span>
            </Col>
          </Row>      
        </Col>
      </Row>
      <Row className="text-center mt-4">
        <Col md={12}>          
          <a target="_blank" href='https://www.facebook.com/profile.php?id=61565194287870&mibextid=kFxxJD'>
            <FontAwesomeIcon icon={faFacebook} />                    
          </a>
          <a target="_blank" href='https://www.instagram.com/vidaconturismomedico?igsh=MWkwdGtlNHM4ejNvcw=='>
            <FontAwesomeIcon icon={faInstagram} />                    
          </a>
          <a target="_blank" href='https://www.tiktok.com/@vida.con.turismo?_t=8pNLnh3ro9d&_r=1'>
            <FontAwesomeIcon icon={faTiktok} />                    
          </a>
          <a target="_blank" href='https://youtube.com/@vidaconturismomedico?si=h29MLGkCxa17qqrO'>
            <FontAwesomeIcon icon={faYoutube} />                    
          </a>
        </Col>
      </Row>      
      <Row className="text-center mt-4">
        <Col md={12}>{t("CopyRight.AllRightsReserved")}{t("CopyRight.RegistrationInProcess")}</Col>
      </Row>         
      <Row className="text-center mt-2">
        <Col md={12}>
          <PrivacyNotice linkText={t("CopyRight.PrivacyNotice.Title")} />
        </Col>
      </Row>            
    </Container>
  );
}

export default Footer;