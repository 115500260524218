import { useTranslation } from 'react-i18next';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Section from '../common/Section';
import styles from "./DestinationSection.module.scss";
import morelia from '../../../assets/images/destination-morelia.jpg';
import sma from '../../../assets/images/destination-sma.jpg';
import irapuato from '../../../assets/images/destination-irapuato.jpg';

function DestinationSection() {
    const { t } = useTranslation();
  return (
    <Section id='destinations' title={t("Destinations.Title")}>
        <Row xs={1} lg={3} className="g-4">
            <Col key={1}>
            <Card>
                <Card.Img variant="top" src={sma} />
                <Card.Body>
                <Card.Title>{t("Destinations.Options.SanMiguelAllende.Title")}</Card.Title>
                <Card.Text>
                    {t("Destinations.Options.SanMiguelAllende.Content")}
                </Card.Text>
                <Card.Link href={t("Destinations.Options.SanMiguelAllende.Link")} target="_blank">{t("Actions.MoreInfo")}</Card.Link>                
                </Card.Body>
            </Card>
            </Col>
            <Col key={2}>
            <Card>
                <Card.Img variant="top" src={morelia} />
                <Card.Body>
                <Card.Title>{t("Destinations.Options.Morelia.Title")}</Card.Title>
                <Card.Text>
                    {t("Destinations.Options.Morelia.Content")}
                </Card.Text>
                <Card.Link href={t("Destinations.Options.Morelia.Link")} target="_blank">{t("Actions.MoreInfo")}</Card.Link>                
                </Card.Body>
            </Card>
            </Col>
            <Col key={3}>
            <Card>
                <Card.Img variant="top" src={irapuato} />
                <Card.Body>
                <Card.Title>{t("Destinations.Options.Irapuato.Title")}</Card.Title>
                <Card.Text>
                    {t("Destinations.Options.Irapuato.Content")}
                </Card.Text>
                <Card.Link href={t("Destinations.Options.Irapuato.Link")} target="_blank">{t("Actions.MoreInfo")}</Card.Link>                
                </Card.Body>
            </Card>
            </Col>
        </Row>
    </Section>
  );
}

export default DestinationSection;