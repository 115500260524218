import React from 'react';
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import { useTranslation } from 'react-i18next';

export default function InstantMessenger() {
    const { t } = useTranslation();  
    const logoPath = './company-logo.jpg';
    return (
        <FloatingWhatsApp
            avatar={logoPath}
            messageDelay ={1}
            phoneNumber={t("InstantMessenger.PhoneNumber")}
            accountName={t("InstantMessenger.AccountName")}
            statusMessage={t("InstantMessenger.StatusMessage")}
            chatMessage={t("InstantMessenger.ChatMessage")}
            placeholder={t("InstantMessenger.Placeholder")}
        />      
    )
  }